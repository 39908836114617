<template>
  <div>
    <v-card
      outlined
      class="mb-3 rounded-lg"
      >
      <v-card-title
        class="justify-center"
        >
        Sobre nosotros
      </v-card-title>
      <v-card-subtitle
        class="text-center"
        >
        Cuéntanos un poco más sobre tu emprendimiento, su historia y qué los trajo hasta aquí.
      </v-card-subtitle>

      <v-card-text>
        <wysiwyg 
          class="mb-3"
          v-model="body" 
          />

        <div
          class="text-right"
          >
          <v-btn
            class="rounded-lg"
            @click="save"
            color="primary"
            >
            Guardar cambios
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    
    <v-snackbar
      color="success"
      v-model="success"
      >
      <div
        class="fill-width text-center"
        >
        Edición exitosa.
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { EditAboutUs } from '@/graphql/mutations/stores'

export default {
  data: () => ({
    body: null,
    success: false
  }),

  props: {
    store: {
      type: Number | String,
      required: true
    }
  },

  created () {
    this.body = this.store.aboutUs.body
  },

  methods: {
    save () {
      this.$apollo.mutate({
        mutation: EditAboutUs,
        variables: {
          input: {
            id: this.store.id,
            attributes: {
              body: this.body
            }
          }
        }
      }).then ( res => {
        this.success = true
      })
    }
  }
}
</script>
